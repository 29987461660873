import React from 'react';
import { FaReact } from 'react-icons/fa';

const Footer = () => {
  function getYear() {
    return new Date().getFullYear();
  }

  return (
    <footer id="footer" className="section bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start wow fadeInUp">
            <p className="mb-2 mb-lg-0">
              Copyright © {getYear()}{' '}
              <a className="fw-600" href="/">
                Amdegroot.dev
              </a>
              . Alle Rechten Voorbehouden.
            </p>
          </div>

          <div className="col-lg-6 wow fadeInUp">
            <p className="mb-0 text-center text-lg-end">
              Gebouwd met{' '}
              <FaReact
                color="#01FFFE"
                size={25}
                title="React"
                className="spin"
              />
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
