import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import { SiFrontendmentor } from 'react-icons/si';

import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from './Tooltip';

const Contact = () => {
  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);
    emailjs
      .sendForm(
        'service_p2lftna',
        'template_ejki2hj',
        form.current,
        'lfGjHyKlQCTaOMv8L'
      )
      .then(
        (result) => {
          document.getElementById('contact-form').reset();
          toast.success('Bericht succesvol verzonden!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          console.log(result.text);
          setSendingMail(false);
        },
        (error) => {
          toast.error('Er is iets fout gegaan!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          console.log(error.text);
          setSendingMail(false);
        }
      );
  };

  return (
    <section id="contact" className="section bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 text-center text-lg-start wow fadeInUp">
            <h2 className="text-10 fw-600 mb-5">Laten we contact opnemen</h2>
            <p className="text-5 mb-5">
              Ik bespreek graag nieuwe projecten en ontwerpuitdagingen. Deel
              zoveel mogelijk informatie, zodat we het maximale kunnen halen uit
              onze eerste ontmoeting.
            </p>
            <h3 className="text-5 fw-600">Woont In:</h3>
            <address className="text-4">Amsterdam, Nederland.</address>
            {/* <h3 className="text-5 fw-600">E-mail:</h3>
            <p className="text-4">info@amdegroot.dev</p> */}
            <ul className="social-icons social-icons-lg justify-content-center justify-content-lg-start mt-5">
              <li className="social-icons-github">
                <Tooltip text="GitHub" placement="top">
                  <a
                    href="https://github.com/Aphelion-im"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-github" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-frontendmentor">
                <Tooltip text="Frontend Mentor" placement="top">
                  <a
                    href="https://www.frontendmentor.io/profile/Aphelion-im"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SiFrontendmentor />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-codepen">
                <Tooltip text="CodePen" placement="top">
                  <a
                    href="https://codepen.io/aphelion-im"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-codepen" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-stackoverflow">
                <Tooltip text="Stack Overflow" placement="top">
                  <a
                    href="https://stackoverflow.com/users/7666820/aphelion-im"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-stack-overflow" />
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
          <div
            className="col-lg-6 ms-auto mt-5 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Jouw project inschatten?
            </h2>
            {/* Contact Form */}
            <form
              id="contact-form"
              className="form-border"
              method="post"
              ref={form}
              onSubmit={sendEmail}
            >
              <div className="row g-4">
                <div className="col-12">
                  <label className="form-label" htmlFor="name">
                    Wat is jouw naam?:
                  </label>
                  <input
                    id="name"
                    name="user_name"
                    type="text"
                    className="form-control py-1"
                    required
                  />
                </div>
                <div className="col-12">
                  <label className="form-label" htmlFor="email">
                    Jouw e-mailadres:
                  </label>
                  <input
                    id="email"
                    name="user_email"
                    type="email"
                    className="form-control py-1"
                    required
                  />
                </div>
                <div className="col-12">
                  <label className="form-label" htmlFor="form-message">
                    Hoe kan ik je helpen?:
                  </label>
                  <textarea
                    id="form-message"
                    name="message"
                    className="form-control py-1"
                    rows={4}
                    required
                    defaultValue={''}
                  />
                </div>
                <div className="col-12 text-center text-lg-start">
                  <button
                    id="submit-btn"
                    className="btn btn-dark rounded-0"
                    type="submit"
                  >
                    {sendingMail ? (
                      <>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm align-self-center me-2"
                        ></span>
                        Verzenden ...
                      </>
                    ) : (
                      <>
                        Verstuur{' '}
                        <span className="ms-3">
                          <i className="fas fa-arrow-right" />
                        </span>
                      </>
                    )}
                  </button>
                </div>
                <ToastContainer />
              </div>
            </form>
            {/* Contact Form end */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
