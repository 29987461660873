import React from 'react';

const Services = () => {

  const services = [
    {
      name: 'Front-End Development',
      desc: 'Met onder andere HTML5, CSS 3, JavaScript en React breng ik jouw creatieve ideeën tot leven.', 
      icon: 'fas fa-code',
    },
    { 
      name: 'Web Design',
      desc: ' Usability en User eXperience (UX) staan voorop bij het ontwerpen van een website en creëer ik voor jou een unieke en gebruiksvriendelijke website of webapplicatie.',
      icon: 'fas fa-paint-brush',
    },
    {
      name: 'Graphic Design',
      desc: 'Naast een website ook nog drukwerk nodig? Dat kan geregeld worden! Of een banner of logo? Geen probleem.',
      icon: 'fas fa-palette',
    },
    {
      name: 'WordPress',
      desc: 'Wordpress sites worden zoekmachine vriendelijk (SEO) gebouwd en geoptimaliseerd voor de zoekmachine én voor de bezoeker.',
      icon: 'fab fa-wordpress',
    },
    {
      name: 'Zoekmachine Optimalisatie (SEO)',
      desc: 'Met een gedegen SEO campagne komt jouw website hoger in de zoekmachines en krijg je meer bezoekers en omzet.',
      icon: 'fas fa-bullhorn',
    },
    { 
      name: 'Responsive Web Design',
      desc: 'Voor een breed scala aan apparaten, tablets en smartphones, kan ik jouw website optimaliseren voor een optimale ervaring op het web.',
      icon: 'fas fa-desktop',
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Diensten</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Hoe ik jouw volgende project kan ondersteunen
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {' '}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
