import React from 'react';
import resumeFile from '../documents/cv-adegroot-april-2024-v3.0.4-web.pdf';

const Resume = () => {
  const educationDetails = [
    {
      yearRange: '2023 - 2024',
      title: 'Full-Stack Developer',
      place: 'NOVI Hogeschool',
      desc: 'Tijdens de opleiding leer je onder andere te werken met HTML, CSS, JavaScript, React, RESTful API\'s, Java, Spring Boot, Hibernate (ORM), JWT, Spring Security, databases (PostgreSQL) en testen met JUnit.',
    },
    {
      yearRange: '2019 - 2020',
      title: 'Front-End Developer & Web Design',
      place: 'College of Multimedia',
      desc: 'Tijdens de opleiding leer je websites te ontwerpen met o.a. Balsamiq en Adobe XD. Daarbij te denken aan typografie, kleurenleer, etc. Daarna een stijlboek te creëren en uiteindelijk dit ontwerp, aan de hand van het stijlboek, om te zetten naar een responsive website (HTML en CSS) met interactieve elementen (JavaScript).',
    },
    {
      yearRange: '2007 - 2008',
      title: 'Elektronisch Voorbereider DTP (Desktop Publishing)',
      place: 'Grafisch Media Instituut Amsterdam',
      desc: 'Tijdens deze opleiding leer je te werken met de Adobe pakketten Photoshop, Illustrator en Indesign en (eigen) ontwerpen klaar te maken voor de drukpers. Producten die aan bod komen tijdens de opleiding zijn flyers, boekjes, posters, logo\'s, kaarten, etc.',
    },
  ];

  const experienceDetails = [
    {
      yearRange: '2012 - 2016',
      title: 'Front-End Developer',
      place: 'Trend Development B.V.',
      desc: 'Bij Trend Development B.V. heb ik diverse Wordpress sites en landingspagina\'s ontwikkeld.',
    },
    {
      yearRange: '2010 - 2012',
      title: 'Internet Marketing Specialist',
      place: 'Create2Send B.V.',
      desc: 'Bij Create2Send B.V. heb ik de diverse internet marketing campages (SEO, SEA, affiliate marketing, etc.) aangestuurd.',
    }
  ];

  const skills = [
    {
      name: 'Web Design',
      percent: 80,
    },
    {
      name: 'HTML/CSS',
      percent: 80,
    },
    {
      name: 'JavaScript',
      percent: 60,
    },
    {
      name: 'React JS',
      percent: 50,
    },
    {
      name: 'Java',
      percent: 50,
    },
    {
      name: 'Spring Boot',
      percent: 40,
    },
    {
      name: 'WordPress',
      percent: 60,
    },
    {
      name: 'SEO',
      percent: 70,
    },
    {
      name: 'TypeScript',
      percent: 8,
    },
    {
      name: 'Sass',
      percent: 35,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Curriculum Vitae</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Een samenvatting van mijn cv
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">Mijn Opleiding</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">Mijn Werkervaring</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">
          Mijn Vaardigheden
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{' '}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + '%' }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
