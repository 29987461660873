import React, { useEffect, useRef, useState } from 'react';
import Isotope from 'isotope-layout';
import ProjectDetailsModal from './ProjectDetailsModal';

const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState('*');
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName('html')[0];
  const isRtl = htmlElement.getAttribute('dir') === 'rtl';

  const filters = {
    REACT: 'React',
    JAVASCRIPT: 'JavaScript',
    WEBDESIGN: 'Webdesign',
    WORDPRESS: 'Wordpress',
  };

  const types = {
    IMAGE: 'image',
    VIDEO: 'video',
    DOCUMENT: 'document',
  };

  const projectsData = [
    {
      title: 'FOUNDFAVE App',
      type: types.DOCUMENT,
      document: {
        projectInfo:
          'Voor de eindopdracht Front-End van NOVI Hogeschool hebben we een design ontworpen in Figma, deze in React gebouwd en daarna gekoppeld aan een RESTful API. Het eindproduct is een zoekmachine waar je Marvel personages kunt opvragen. Het is mogelijk om een account te registreren en in te loggen. Er is een ledengedeelte waar je favorieten kunt opslaan en bekijken. Verder is er een "Dark mode" optie dat prettiger is voor de ogen. De API die ik had gemaakt voor de back-end eindopdracht is helaas nog niet gekoppeld aan deze React front-end. Deze Java/Spring Boot API kun je onderaan deze pagina bekijken!',
        client: 'NOVI Hogeschool',
        technologies:
          'React, Figma, React Hook Form, Axios, JavaScript, HTML5, CSS 3, Responsive Web Design, Affinity Photo, API, WebStorm, GitHub, Netlify',
        industry: 'Web development',
        date: '16 juli 2023',
        url: {
          name: 'FOUNDFAVE App',
          link: 'https://foundfave.netlify.app',
        },
        github: {
          name: 'GitHub repo Foundfave App',
          link: 'https://github.com/Aphelion-im/FOUNDFAVE-frontend-eindopdracht-react',
        },
        extra: {
          name: 'FOUNDFAVE API back-end (Momenteel niet gekoppeld aan deze React front-end)',
          link: 'https://github.com/Aphelion-im/FOUNDFAVE-API-backend-eindopdracht-java',
        },
        sliderImages: [
          'images/projects/foundfave-app/about-dark-mode-700x750-o.jpg',
          'images/projects/foundfave-app/contact-dark-mode-700x750-o.jpg',
          'images/projects/foundfave-app/favorites-logged-out-dark-mode-700x750-o.jpg',
          'images/projects/foundfave-app/hero-details-dark-mode-700x750-o.jpg',
          'images/projects/foundfave-app/home-dark-mode-700x750-o.jpg',
          'images/projects/foundfave-app/home-light-mode-700x750-o.jpg',
          'images/projects/foundfave-app/search-result-dark-mode-700x750-o.jpg',
          'images/projects/foundfave-app/search-results-spider-dark-mode-700x750-o.jpg',
          'images/projects/foundfave-app/sign-in-dark-mode-700x750-o.jpg',
        ],
      },

      thumbImage:
        'images/projects/foundfave-app/search-results-spider-dark-mode-700x750-o.jpg',

      categories: [filters.REACT],
    },
    {
      title: 'Cuisine',
      type: types.DOCUMENT,
      document: {
        projectInfo:
          'Voor de eindopdracht, module 2 Front-end developer, van College of Multimedia heb ik aan de hand van een aangeleverd conceptboek de website van Cuisine geproduceerd. De site bevat een image slider, een producten- en receptenfilter, een interactieve koe en een contactformulier die interactief zijn gemaakt met JavaScript.',
        client: 'College of Multimedia',
        technologies:
          'JavaScript, HTML5, CSS 3, Responsive Web Design, Adobe Photoshop, Visual Studio Code, GitHub LFS (Github Large File Storage), Netlify, Formspree',
        industry: 'Web development',
        date: '14 april 2020',
        url: {
          name: 'CMM module 2 eindopdracht: Cuisine',
          link: 'https://cmm-front-end-developer-module-2-mm-b.netlify.app',
        },
        github: {
          name: 'GitHub repo CMM module 2 eindopdracht',
          link: 'https://github.com/Aphelion-im/CMM-Front-End-Developer-Module-2-MM-B',
        },
        extra: {
          name: 'Cuisine conceptboek PDF',
          link: 'https://github.com/Aphelion-im/CMM-Front-End-Developer-Module-2-MM-B/blob/main/_documentatie/Cuisine-conceptboek.pdf',
        },
        sliderImages: [
          'images/projects/cmm-module-2-cuisine/campagne-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/contact-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/mijn-account-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/nieuws-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/nieuws-welk-deel-van-de-koe-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/nieuws-zelf-sushi-maken-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/over-ons-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/recept-bami-soep-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/recept-shakshuka-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/recept-tagliatelle-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/recept-thaise-curry-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/recept-thaise-kip-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/recept-vegetarische-sushi-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/recept-vispan-met-schelvis-en-prei-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/recept-wortelsoep-met-kurkuma-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/recepten-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/shop-700x750-o.jpg',
          'images/projects/cmm-module-2-cuisine/winkelwagen-700x750-o.jpg',
        ],
      },

      thumbImage: 'images/projects/cmm-module-2-cuisine/home-700x750-o.jpg',

      categories: [filters.JAVASCRIPT],
    },
    {
      title: 'Bee Strong Webshop',
      type: types.DOCUMENT,
      document: {
        projectInfo:
          'Voor de eindopdracht, module 1 Webdesigner, van College of Multimedia kregen we de opdracht om onze eigen ontwerpen (Wireframes, prototypes en stijlboek) om te zetten naar HTML5, CSS 3 en JavaScript. De Bee Strong Webshop en het bijhorende stijlboek zijn geheel door mij ontwikkeld en zijn ontworpen met o.a. Balsamiq, Adobe XD, Adobe Color, Adobe InDesign, etc.',
        client: 'College of Multimedia',
        technologies:
          'Adobe XD, Balsamiq, HTML5, CSS 3, Adobe Photoshop, Visual Studio Code, GitHub, Netlify',
        industry: 'Web development',
        date: '14 december 2019',
        url: {
          name: 'CMM module 1 eindopdracht: Bee strong webshop',
          link: 'https://cmm-web-design-module-1-mm-a-2.netlify.app',
        },
        github: {
          name: 'GitHub repo CMM module 1 eindopdracht: Bee strong webshop',
          link: 'https://github.com/Aphelion-im/CMM-Web-Design-Module-1-MM-A-2',
        },
        extra: {
          name: 'Bee Strong Webshop Conceptboek PDF',
          link: 'https://github.com/Aphelion-im/CMM-Web-Design-Module-1-MM-A-1/blob/main/conceptbook-v134-printerette-reduced.pdf',
        },
        sliderImages: [
          'images/projects/cmm-module-1-bee-strong-webshop/blog-bee-strong-700x750-o.jpg',
          'images/projects/cmm-module-1-bee-strong-webshop/contact-bee-strong-700x750-o.jpg',
          'images/projects/cmm-module-1-bee-strong-webshop/honing-met-kaneel-een-gouden-combinatie-700x750-o.jpg',
          'images/projects/cmm-module-1-bee-strong-webshop/informatie-700x750-o.jpg',
          'images/projects/cmm-module-1-bee-strong-webshop/inloggen-mijn-account-700x750-o.jpg',
          'images/projects/cmm-module-1-bee-strong-webshop/langnese-bijenhoning-goudhelder-250-gram-700x750-o.jpg',
          'images/projects/cmm-module-1-bee-strong-webshop/login-admin-pagina-bee-strong-700x750-o.jpg',
          'images/projects/cmm-module-1-bee-strong-webshop/productcategorie-honing-700x750-o.jpg',
          'images/projects/cmm-module-1-bee-strong-webshop/webshop-bee-strong-700x750-o.jpg',
          'images/projects/cmm-module-1-bee-strong-webshop/winkelwagen-700x750-o.jpg',
          'images/projects/cmm-module-1-bee-strong-webshop/zoekresultaten-producten-bee-strong-700x750-o.jpg',
        ],
      },

      thumbImage:
        'images/projects/cmm-module-1-bee-strong-webshop/home-700x750-o.jpg',

      categories: [filters.WEBDESIGN],
    },
    {
      title: 'Jurassic Park',
      type: types.DOCUMENT,
      document: {
        projectInfo:
          'Voor module 2 Front-end developer van College of Multimedia kregen we de opdracht om een interactieve kaart maken met Image maps en JavaScript en heb ik een interactieve kaart gemaakt van een van mijn favoriete films: Jurassic Park. Op de kaart en legenda kun je van diverse dinosauriërs en attracties de informatie opvragen.',
        client: 'College of Multimedia',
        technologies:
          'HTML5, CSS 3, JavaScript, Adobe XD, Adobe Photoshop, Visual Studio Code, GitHub, Netlify',
        industry: 'Web development',
        date: '14 maart 2020',
        url: {
          name: 'CMM module 2: Interactieve kaart Jurassic Park',
          link: 'https://cmm-front-end-developer-module-2-mm-4.netlify.app',
        },
        github: {
          name: 'GitHub repo CMM module 2 eindopdracht: Jurassic Park',
          link: 'https://github.com/Aphelion-im/CMM-Front-End-Developer-Module-2-MM-4',
        },
        extra: {
          name: '',
          link: '',
        },
        sliderImages: [
          'images/projects/cmm-module-2-jurassic-park/jurassic-park-isla-nublar-700x750-o.jpg',
        ],
      },

      thumbImage:
        'images/projects/cmm-module-2-jurassic-park/t-rex-info-700x750-o.jpg',

      categories: [filters.JAVASCRIPT],
    },
    {
      title: 'Bakery',
      type: types.DOCUMENT,
      document: {
        projectInfo:
          'Voor de module 2 front-end developer van College of Multimedia kregen we de opdracht, om aan de hand van een prototype, het design om te zetten naar een responsive website in HTML5, CSS 3 en de benodigde media queries. Een hamburger menu is te zien op de lagere resoluties.',
        client: 'College of Multimedia',
        technologies:
          'HTML5, CSS 3, Responsive Web Design, Adobe Photoshop, Visual Studio Code, GitHub, Netlify',
        industry: 'Web development',
        date: '14 februari 2020',
        url: {
          name: 'CMM module 2: Bakery',
          link: 'https://cmm-front-end-developer-module-2-mm-3.netlify.app/',
        },
        github: {
          name: 'GitHub repo CMM module 2 MM-3: Bakery',
          link: 'https://github.com/Aphelion-im/CMM-Front-End-Developer-Module-2-MM-3',
        },
        extra: {
          name: '',
          link: '',
        },
        sliderImages: [
          'images/projects/cmm-module-1-bakery/home-bottom-700x750-o.jpg',
          'images/projects/cmm-module-1-bakery/home-mobile-top-700x750-o.jpg',
          'images/projects/cmm-module-1-bakery/home-mobile-latest-700x750-o.jpg',
          'images/projects/cmm-module-1-bakery/home-mobile-jean-700x750-o.jpg',
          'images/projects/cmm-module-1-bakery/home-mobile-footer-700x750-o.jpg',
        ],
      },

      thumbImage: 'images/projects/cmm-module-1-bakery/home-700x750-o.jpg',

      categories: [filters.WEBDESIGN],
    },
    {
      title: 'Game of thrones: The great houses of Westeros',
      type: types.DOCUMENT,
      document: {
        projectInfo:
          'Voor module 1, Webdesigner, van College of Multimedia kregen we de opdracht om een eigen website ontwerpen met Adobe XD en dit design daarna om te zetten naar HTML5 en CSS 3. De Game of thrones website is geheel mijn eigen ontwerp en is ontworpen met o.a. Balsamiq en Adobe XD.',
        client: 'College of Multimedia',
        technologies:
          'HTML5, CSS 3, Balsamiq, Adobe XD, Adobe Photoshop, Visual Studio Code, GitHub, Netlify',
        industry: 'Web development',
        date: '14 november 2019',
        url: {
          name: 'CMM module 1: Game of thrones',
          link: 'https://cmm-web-design-module-1-mm-2.netlify.app',
        },
        github: {
          name: 'GitHub repo CMM module 2 eindopdracht: Game of thrones',
          link: 'https://github.com/Aphelion-im/CMM-Web-Design-Module-1-MM-2',
        },
        extra: {
          name: '',
          link: '',
        },
        sliderImages: [
          'images/projects/cmm-module-1-game-of-thrones/house-lannister-700x750-o.jpg',
          'images/projects/cmm-module-1-game-of-thrones/house-lannister-tyrion-lannister-700x750-o.jpg',
          'images/projects/cmm-module-1-game-of-thrones/house-lannister-jaime-lannister-700x750-o.jpg',
          'images/projects/cmm-module-1-game-of-thrones/house-stark-700x750-o.jpg',
          'images/projects/cmm-module-1-game-of-thrones/house-stark-arya-stark-700x750-o.jpg',
          'images/projects/cmm-module-1-game-of-thrones/house-stark-jon-snow-700x750-o.jpg',
          'images/projects/cmm-module-1-game-of-thrones/house-stark-sansa-stark-700x750-o.jpg',
          'images/projects/cmm-module-1-game-of-thrones/house-targaryen-700x750-o.jpg',
          'images/projects/cmm-module-1-game-of-thrones/house-targaryen-daenerys-targaryen-700x750-o.jpg',
        ],
      },

      thumbImage:
        'images/projects/cmm-module-1-game-of-thrones/home-700x750-o.jpg',

      categories: [filters.WEBDESIGN],
    },
    {
      title: 'Junior Memory 1969',
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Toen ik nog jong was, een jaar of 8 á 9, speelde ik thuis aan de tafel vaak Ravensburger's Junior Memory en heb dit spel nagemaakt met HTML5, CSS 3 en JavaScript, zodat ik mijn jeugd weer kan herbeleven.",
        client: 'Persoonlijk project',
        technologies:
          'HTML5, CSS 3, JavaScript, Responsive Web Design, Affinity Photo, Visual Studio Code, GitHub, Netlify',
        industry: 'Web development',
        date: '6 november 2022',
        url: {
          name: 'Junior Memory 1969',
          link: 'https://junior-memory-1969.netlify.app',
        },
        github: {
          name: 'GitHub repo Junior Memory 1969',
          link: 'https://github.com/Aphelion-im/Junior-memory-1969',
        },
        extra: {
          name: '',
          link: '',
        },
        sliderImages: [
          'images/projects/junior-memory-1969-ravensburger/memory-1-700x750px.jpg',
          'images/projects/junior-memory-1969-ravensburger/memory-2-700x750px.jpg',
          'images/projects/junior-memory-1969-ravensburger/memory-3-700x750px.jpg',
        ],
      },

      thumbImage:
        'images/projects/junior-memory-1969-ravensburger/memory-doos-700x750.jpg',

      categories: [filters.JAVASCRIPT],
    },
    {
      title: 'Noodverlichtingspecialist.com',
      type: types.DOCUMENT,
      document: {
        projectInfo:
          'Voor onze cliënt, Caspar Norg, hebben we een Wordpress site ontwikkeld, het logo en banners ontworpen, de site SEO-proof gebouwd en diverse plugins met de gewenste functionaliteit geïnstalleerd en geconfigureerd. Er zijn diverse security plugins geïnstalleerd en is er, onder andere, de typische login url, /wp-admin/ gewijzigd.',
        client: 'Caspar Norg',
        technologies:
          'Wordpress, HTML5, CSS 3, Responsive Web Design, Adobe Illustrator',
        industry: 'Web development',
        date: '18 juli 2015',
        url: {
          name: 'Noodverlichtingspecialist.com',
          link: 'https://noodverlichtingspecialist.com',
        },
        github: {
          name: '',
          link: '',
        },
        extra: {
          name: '',
          link: '',
        },
        sliderImages: [
          'images/projects/noodverlichtingspecialist.com/blog-700x750px.jpg',
          'images/projects/noodverlichtingspecialist.com/blog-artikel-700x750px.jpg',
          'images/projects/noodverlichtingspecialist.com/contact-700x750px.jpg',
          'images/projects/noodverlichtingspecialist.com/feiten-700x750px.jpg',
          'images/projects/noodverlichtingspecialist.com/tarieven-700x750px.jpg',
          'images/projects/noodverlichtingspecialist.com/vind-uw-specialist-700x750px.jpg',
          'images/projects/noodverlichtingspecialist.com/wall-of-safety-700x750px.jpg',
        ],
      },

      thumbImage:
        'images/projects/noodverlichtingspecialist.com/home-700x750px.jpg',

      categories: [filters.WORDPRESS],
    },
    {
      title: 'Droogdak.nl',
      type: types.DOCUMENT,
      document: {
        projectInfo: 'Voor Dakdekkersbedrijf Droog, hebben we een Wordpress site ontwikkeld. De klant wou de mogelijkheid om foto\'s in een slider te weergeven. Ook hebben we een contactformulier geïnstalleerd. Er zijn diverse security plugins geïnstalleerd en is er, onder andere, de typische login url, /wp-admin/ gewijzigd.',
        client: 'Dakdekkersbedrijf Droog',
        technologies: 'Wordpress, HTML5, CSS 3, Responsive Web Design',
        industry: 'Web development',
        date: '12 november 2015',
        url: {
          name: 'Droogdak.nl',
          link: 'https://droogdak.nl',
        },
        github: {
          name: '',
          link: '',
        },
        extra: {
          name: '',
          link: '',
        },
        sliderImages: [
          'images/projects/droogdak.nl/contact-700x750px.jpg',
          'images/projects/droogdak.nl/dakwerkzaamheden-700x750px.jpg',
          'images/projects/droogdak.nl/referenties-700x750px.jpg',
        ],
      },

      thumbImage: 'images/projects/droogdak.nl/home-700x750px.jpg',

      categories: [filters.WORDPRESS],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope('.portfolio-filter', {
      itemSelector: '.filter-item',
      layoutMode: 'masonry',
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, [isRtl]);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === '*'
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(' ');
    }
  };

  return (
    <>
      <section id="portfolio" className={'section bg-light'}>
        <div className={'container'}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Een selectie van mijn projecten
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              'portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp'
            }
          >
            <li className="nav-item">
              <button
                className={'nav-link ' + (filterKey === '*' ? 'active' : '')}
                onClick={handleFilterKeyChange('*')}
              >
                Alles
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    'nav-link ' + (filterKey === oneKey ? 'active' : '')
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      'col-sm-6 col-lg-4 filter-item ' +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {' '}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            {/* <span className="text-light">Categorie</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
